import { render, staticRenderFns } from "./MoleculeBuildingFloorLabel.vue?vue&type=template&id=3c8c3452&scoped=true&"
import script from "./MoleculeBuildingFloorLabel.vue?vue&type=script&lang=js&"
export * from "./MoleculeBuildingFloorLabel.vue?vue&type=script&lang=js&"
import style0 from "./MoleculeBuildingFloorLabel.vue?vue&type=style&index=0&id=3c8c3452&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c8c3452",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomsAtomLoader: require('/app/components/atoms/AtomLoader.vue').default,MoleculesProjectBuildingMoleculeAvailabilitySpaceFilterResultBuilding: require('/app/components/molecules/project/building/MoleculeAvailabilitySpaceFilterResultBuilding.vue').default})
